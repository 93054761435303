// src/Footer.js
import React from "react";
import "./Footer.css"; // Import CSS for styling
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer-left"></div>
      <div className="footer-center">
        <p>&copy; Copyright 2024. All Rights Reserved.</p>
      </div>
      <div className="footer-right">
        <p className="text-primary" style={{ cursor: "pointer" }} onClick={() => {
            navigate("/contact-us");
        }}>
          Having an issue?
        </p>
      </div>
    </footer>
  );
};

export default Footer;
