export async function fetchInstructions(labelId, type) {
  try {
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/general/instructions/1?type=${type}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const content = await rawResponse.json();
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: "" };
    } else {
      return { body: [], success: false, message: content.message };
    }
  } catch (error) {
    return;
  }
}

export async function fetchMetaData() {
  try {
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/general/meta-data/1`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const content = await rawResponse.json();
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: "" };
    } else {
      return { body: [], success: false, message: content.message };
    }
  } catch (error) {
    return;
  }
}

export async function fetchNotifications(id) {
  try {
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/general/notifications/1`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const content = await rawResponse.json();
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: "" };
    } else {
      return { body: [], success: false, message: content.message };
    }
  } catch (error) {
    return;
  }
}

export async function fetchNotificationsAttachment(id) {
  const rawResponse = await fetch(
    process.env.REACT_APP_API +
      `/v1/api/general/notifications/attachment/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const content = await rawResponse.json();
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: "" };
  } else {
    return { body: [], success: false, message: content.message };
  }
}

export async function checkRegisterCandidate(formData) {
  const url = `${process.env.REACT_APP_API}/v1/api/general/check-registration`;
  const rawResponse = await fetch(url, {
    method: "Post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  const content = await rawResponse.json();
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: "" };
  } else {
    return { body: [], success: false, message: content.message };
  }
}

export async function registerCandidate(formData) {
  const url = `${process.env.REACT_APP_API}/v1/api/general/register`;
  const rawResponse = await fetch(url, {
    method: "Post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  const content = await rawResponse.json();
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: "" };
  } else {
    return { body: [], success: false, message: content.message };
  }
}

export async function verifyRegisterOtp(formData) {
  const url = `${process.env.REACT_APP_API}/v1/api/general/verify-otp`;
  const rawResponse = await fetch(url, {
    method: "Post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: formData,
  });

  const content = await rawResponse.json();
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: "" };
  } else {
    return { body: [], success: false, message: content.message };
  }
}
